<template>
  <div class="container">
    <My-Breadcrumb style="position: absolute; top: 145px"
                   :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 菜单类型 -->
      <div class="show_left">
        <a-page-header style="padding-left: 0; padding-right: 0"
                       title="菜品类型">
          <template slot="extra">
            <a-button type="primary"
                      icon="plus"
                      @click="showTypeModal('add')">新增类型</a-button>
          </template>
        </a-page-header>
        <a-table :columns="columnsLeft"
                 :data-source="menuTypeData"
                 :loading="loading"
                 :rowKey="(menuTypeData) => menuTypeData.id"
                 :pagination="false">
          <template slot="action"
                    slot-scope="record">
            <a-button type="link"
                      @click="showTypeModal(record)" class="btn-diy" >编辑</a-button>
            <a-divider type="vertical"/>
            <a-button type="link"
                      @click="deleteType(record)" class="btn-diy" >删除</a-button>
            <a-divider type="vertical"/>
            <a-button type="link"
                      @click="searchByType(record)" class="btn-diy" >查询</a-button>
          </template>
        </a-table>
      </div>
      <!-- 菜品列表 -->
      <div class="show_right">
        <a-page-header style="padding-left: 0; padding-right: 0"
                       title="菜品列表">
          <template slot="extra">
            <a-input placeholder="请输入菜品名称"
                     v-model="queryParam.title"
                     style="width:150px;"></a-input>
            <a-button 
                      type="primary"
                      @click="getTableData"
                      style="margin-left: 10px">搜索</a-button>
            <a-button 
                      @click="reset"
                      style="margin-left: 10px">刷新</a-button>
            <a-button type="primary"
                      
                      @click="showModal('add')"
                      style="margin-left: 10px">添加菜品</a-button>

          </template>
        </a-page-header>
        <a-table :columns="columns"
                 :data-source="tableData"
                 :loading="loading"
                 :rowKey="(tableData) => tableData.id"
                 :pagination="pagination"
                 @change="handleTableChange">
          <template slot="link_url"
                    slot-scope="record">
            <img style="width: 50px; heigth: 50px"
                 :src="record.link_url"
                 alt />
          </template>
          <template slot="type"
                    slot-scope="record">
            {{ record.type.title }}
          </template>
          <template slot="cycle_status"
                    slot-scope="record">
            {{ record.cycle_status == 0 ? "开启": "关闭"}}
          </template>
          <template slot="cycleDayName"
                    slot-scope="record">
            {{ record.cycle_status == 0 ? record.cycleDayName : '无'}}
          </template>

          <template slot="action"
                    slot-scope="record">
            <a-button type="link"
                      @click="showModal(record)" class="btn-diy" >编辑</a-button>
            <a-divider type="vertical" />
            <a-button type="link"
                      @click="copyFn(record)" class="btn-diy">复制</a-button>
            <a-divider type="vertical" />
            <a-button type="link"
                      @click="deleteFn(record)" class="btn-diy">删除</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <!-- 添加/编辑菜品 -->
    <a-modal v-model="visible"
             :title="addMenu ? '添加菜品' : '编辑菜品'"
             @ok="handleUpdate"
             :width="1000"
             :okText="addMenu ? '新建' : '保存'">
      <a-form-model :model="menuParam"
                    :rules="rules"
                    ref="menuForm"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }">
        <a-form-model-item label="菜品类型">
          <a-select v-model="menuParam.style"
                    @change="selectMenu"
                    :disabled="true">
            <a-select-option v-for="item in menuTypeData"
                             :key="item.id"
                             :value="item.id">{{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="菜品名称"
                           prop="title">
          <a-input v-model="menuParam.title"
                   placeholder="请输入菜品名称" />
        </a-form-model-item>
        <a-form-model-item label="菜品价格"
                           prop="price">
          <a-input type='number'
                   v-model="menuParam.price"
                   placeholder="请输入菜品价格" />
        </a-form-model-item>
        <a-form-model-item label="菜品限报份数"
                           prop="upper_count">
          <a-input v-model="menuParam.upper_count"
                   placeholder="菜品限报份数" />
        </a-form-model-item>

        <a-form-model-item label="预设库存"
                           prop="stock">
          <a-input v-model="menuParam.stock"
                   placeholder="预设库存" />
        </a-form-model-item>
        <a-form-model-item label="首份减免"
                           prop="first_price_sub">
          <a-input v-model="menuParam.first_price_sub"
                   placeholder="首份减免" />
        </a-form-model-item>
        <a-form-model-item label="权重"
                           prop="weight">
          <a-input v-model="menuParam.weight"
                   placeholder="权重" />
        </a-form-model-item>
        <a-form-model-item label="简介"
                           prop="content">
          <a-textarea v-model="menuParam.content"
                      placeholder="请输入简介"
                      auto-size />
        </a-form-model-item>
        <a-form-model-item
                           label="菜品开放范围">
          <a-select v-model="menuParam.open_all"
                    @change="selectOpenAll">
            <a-select-option :value="0">仅对指定部门职务开放</a-select-option>
            <a-select-option :value="1">全部人员开放</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="showCheckOffice">
          <template slot="label">
            <a-checkbox :indeterminate="indeterOffice"
                        :checked="checkAllOffice"
                        @change="onCheckAllOffice">开放部门
            </a-checkbox>
          </template>
          <a-checkbox-group v-model="menuParam.open_office"
                            style="width:100%;">
            <a-row>
              <a-col style="text-overflow: ellipsis;white-space: nowrap;overflow : hidden;margin:10px 0;"
                     v-for="item in officesData"
                     :key="item.key"
                     :span="
                  officesData.length == 1
                    ? 24
                    : officesData.length == 2
                    ? 12
                    : officesData.length == 3
                    ? 8
                    : 6
                ">
                <a-checkbox :value="item.key"></a-checkbox>{{ item.value}}
                <!-- <a-tooltip>
                  <template slot="title">
                    {{ item.value}}
                  </template>
                  {{ item.value}}
                </a-tooltip> -->
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item v-if="showCheckOffice">
          <template slot="label">
            <a-checkbox :indeterminate="indeterPost"
                        :checked="checkAllPost"
                        @change="onCheckAllPost">开放职务</a-checkbox>
          </template>
          <a-checkbox-group v-model="menuParam.open_post"
                            style="width:100%;">
            <a-row>
              <a-col style="text-overflow: ellipsis;white-space: nowrap;overflow : hidden;margin:10px 0;"
                     v-for="item in postsData"
                     :key="item.key"
                     :span="
                  postsData.length == 1
                    ? 24
                    : postsData.length == 2
                    ? 12
                    : postsData.length == 3
                    ? 8
                    : 6
                ">
                <a-checkbox :value="item.key"></a-checkbox>{{item.value}}
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>

        <a-form-model-item v-if="menuParam.cycle_status == 0">
          <template slot="label">
            <a-checkbox :indeterminate="indeterMealTime"
                        :checked="checkAllMealTime"
                        @change="onCheckAllMealTime">就餐时段
            </a-checkbox>
          </template>
          <a-checkbox-group v-model="menuParam.meal_id"
                            style="width:100%;">
            <a-row>
              <a-col style="text-overflow: ellipsis;white-space: nowrap;overflow : hidden;margin:10px 0;"
                     v-for="item in mealTimesData"
                     :key="item.key"
                     :span="
                  mealTimesData.length == 1
                    ? 24
                    : mealTimesData.length == 2
                    ? 12
                    : mealTimesData.length == 3
                    ? 6
                    : 6
                ">
                <a-checkbox :value="item.key"></a-checkbox>{{ item.value }}
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>

        <a-form-model-item v-if="menuParam.cycle_status == 0">
          <template slot="label">
            <a-checkbox :indeterminate="indeterCircleDay"
                        :checked="checkAllDay"
                        @change="onCheckAllDay">选择菜品循环周期
            </a-checkbox>
          </template>
          <a-checkbox-group v-model="menuParam.cycle_days"
                            style="width:100%;">
            <a-row>
              <a-col style="margin:10px 0;"
                     v-for="item in circleDay"
                     :key="item.key"
                     :span="6">
                <a-checkbox :value="item.key">{{ item.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item label="自动上架菜品">
          <a-select v-model="menuParam.cycle_status"
                    @change="selectCycleStatus">
            <a-select-option :value="0">开启</a-select-option>
            <a-select-option :value="1">关闭</a-select-option>
          </a-select>
          <a-button icon="question"
                    @click="showTip"></a-button>
        </a-form-model-item>
        <a-form-model-item label="菜品图片（300kb以内）">
          <a-upload list-type="picture-card"
                    @change="changeFile"
                    :customRequest="customRequest"
                    :file-list="fileList"
                    :remove="removePic"
                    @preview="handlePreview"
                    :beforeUpload="beforeUpload">
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 预览图 -->
    <a-modal :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <img alt="example"
           style="width: 100%"
           :src="previewImage" />
    </a-modal>

    <!-- 添加/编辑菜品类型 -->
    <a-modal v-model="typeModalVisible"
             :title="addType ? '添加菜品类型' : '编辑菜品类型'"
             @ok="handleUpdateType"
             okText="保存">
      <a-form-model :model="typeParam"
                    :rules="typeRules"
                    ref="TypeForm"
                    :label-col="{ span: 7 }"
                    :wrapper-col="{ span: 14 }">
        <a-form-model-item label="类型名称"
                           prop="title">
          <a-input v-model="typeParam.title"
                   placeholder="请输入类型名称" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--复制菜品前询问 -->
    <a-modal v-model="showDeleteOrSave"
             title="提示">
      复制成功后是否保留该菜品?
      <template slot="footer">
        <a-button @click="deleteMealFn">删除</a-button>
        <a-button type="primary" @click="saveMealFn">保留</a-button>
      </template>
    </a-modal>

    <!--复制菜品时选择欲复制到的菜品类型 -->
    <a-modal v-model="showTypes"
             title="复制菜品到哪个菜品类型？"
             @ok="askHandleTargetType"
             :confirm-loading="confirmLoading"
             okText="确认">
      <a-radio-group v-model="TargetType">
        <a-radio v-for="item in menuTypeData"
                 :key="item.id"
                 :value="item.id">
          {{item.title}}
        </a-radio>
      </a-radio-group>
    </a-modal>

    <!--补充信息 -->
    <a-modal v-model="showReplenish"
             title="请补充以下信息"
             @ok="saveReplenish"
             :confirm-loading="confirmLoading2"
             okText="提交保存">
      <a-form-model-item>
        <template slot="label">
          <a-checkbox :indeterminate="indeterMealTime2"
                      :checked="checkAllMealTime2"
                      @change="onCheckAllMealTime2">就餐时段
          </a-checkbox>
        </template>
        <a-checkbox-group v-model="copyParam.meal_id"
                          style="width:100%;">
          <a-row>
            <a-col style="text-overflow: ellipsis;white-space: nowrap;overflow : hidden;margin:10px 0;"
                   v-for="item in mealTimesData"
                   :key="item.key"
                   :span="
                  mealTimesData.length == 1
                    ? 24
                    : mealTimesData.length == 2
                    ? 12
                    : mealTimesData.length == 3
                    ? 6
                    : 6
                ">
              <a-checkbox :value="item.key"></a-checkbox>{{ item.value }}
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>

      <a-form-model-item>
        <template slot="label">
          <a-checkbox :indeterminate="indeterCircleDay2"
                      :checked="checkAllDay2"
                      @change="onCheckAllDay2">选择菜品循环周期
          </a-checkbox>
        </template>
        <a-checkbox-group v-model="copyParam.cycle_days"
                          style="width:100%;">
          <a-row>
            <a-col style="margin:10px 0;"
                   v-for="item in circleDay"
                   :key="item.key"
                   :span="6">
              <a-checkbox :value="item.key">{{ item.label }}</a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="自动上架菜品">
        <a-select v-model="copyParam.cycle_status"
                  @change="selectCycleStatus2">
          <a-select-option :value="0">开启</a-select-option>
          <a-select-option :value="1">关闭</a-select-option>
        </a-select>
        <a-button icon="question"
                  @click="showTip"></a-button>
      </a-form-model-item>
    </a-modal>

  </div>
</template>

<script>
//引入接口api
import {
  menuIndex,
  menuCreate,
  menuPicUpload,
  menuDetail,
  menuDelete,
  menuUpdate,
  menuTypes,
  menuTypeCreate,
  menuTypeUpdate,
  menuTypeDelete,
  officesWithout,
  postsWithout,
  mealtimeListWithout,
} from "@/request/api";
//引入封装的组件
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "菜品库",
        },
      ],

      //****菜品列表相关参数
      queryParam: {
        page: 1,
        style: "",
        title: ""
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态
      columns: [
        // 列配置
        {
          title: "菜单图片",
          key: "link_url",
          className:'th-style',
          scopedSlots: { customRender: "link_url" },
        },
        {
          title: "名称",
          key: "title",
          className:'th-style',
          dataIndex: "title",
        },
        {
          title: "价格",
          key: "price",
          className:'th-style',
          dataIndex: "price",
        },
        {
          title: "已上架",
          key: "mealTimes",
          className:'th-style',
          dataIndex: "mealTimes",
        },
        {
          title: "循环周期",
          key: "cycleDayName",
          className:'th-style',
          scopedSlots: { customRender: "cycleDayName" },

        },
        {
          title: "自动上架",
          key: "cycle_status",
          className:'th-style',
          scopedSlots: { customRender: "cycle_status" },
        },
        {
          title: "类型",
          key: "type",
          className:'th-style',
          scopedSlots: { customRender: "type" },
        },
        {
          title: "操作",
          key: "action",
          className:'th-style',
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        //页配置
        total: 0,
        pageSize: 15,
        current: 1,
      },
      visible: false,
      addMenu: false,
      menuParam: {
        title: "", //必填
        price: "", //必填
        style: "", //is_fixed=0时必选
        stock: 9999,
        is_fixed: 1, //是否固定餐 1:是 0 ：否
        open_office: [],
        open_post: [],
        first_price_sub: 0,
        link_url: "",
        upper_count: 1,
        cycle_days: [],
        content: "",
        meal_id: [],
        cycle_status: 0,
        weight: 0,
        open_all:0
      },
      officesData: [],
      postsData: [],
      mealTimesData: [],
      indeterOffice: false, //控制部门全选样式
      checkAllOffice: false, //部门全选标识
      indeterPost: false, //控制职务全选样式
      checkAllPost: false, //职务全选标识
      indeterCircleDay: false,
      checkAllDay: false,
      indeterMealTime: false, //控制就餐时段全选样式
      checkAllMealTime: false, //就餐时段全选标识
      circleDay: [
        //循环周期
        {
          key: 1,
          label: "周一",
        },
        {
          key: 2,
          label: "周二",
        },
        {
          key: 3,
          label: "周三",
        },
        {
          key: 4,
          label: "周四",
        },
        {
          key: 5,
          label: "周五",
        },
        {
          key: 6,
          label: "周六",
        },
        {
          key: 0,
          label: "周日",
        },
      ],
      fileList: [],
      previewVisible: false,
      previewImage: "",
      rules: {
        title: [{ required: true, message: "请输入菜品名", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格(价格必须为数字)", trigger: "blur" }],
        weight: [{ required: true, message: "请输入权重(权重必须为数字)", trigger: "blur" }]
      },
      fixedId: "",//该食堂固定餐的id
      updateId: "",
      // disabled: false,

      //*****菜单类型相关配置
      menuTypeData: [],
      columnsLeft: [
        {
          title: "名称",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      typeModalVisible: false,
      addType: false,
      typeParam: {
        title: "",
      },
      typeRules: {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      searchTypeId: "",
      showTypes: false,
      TargetType: '',
      copy: {},
      copyed: {},
      isSave: false,
      copyParam: {},
      showReplenish: false,
      indeterMealTime2: false, //控制就餐时段全选样式
      checkAllMealTime2: false, //就餐时段全选标识
      indeterCircleDay2: false,
      checkAllDay2: false,
      confirmLoading: false,
      confirmLoading2: false,
      showDeleteOrSave:false,
      showCheckOffice:true
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新
    reset () {
      this.queryParam = {
        page: 1,
        style: this.searchTypeId,
        title: ''
      };
      this.pagination.current = 1;
      this.getTableData();
      // this.getMenuType();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      menuIndex(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data.map((item) => {
          if (item.meal_id != 0) {
            let result = [];
            let arr = item.meal_id.split(",");
            arr = arr.map((item) => {
              return item * 1;
            });
            this.mealTimesData.forEach((item) => {
              if (arr.indexOf(item.key) != -1) {
                result.push(item.value);
              }
            });
            item.mealTimes = result.join("，");
          } else {
            item.mealTimes = "无";
          }

          if (item.cycle_days[0] === "") {
            item.cycleDayName = "无";
          } else {
            let result = [],
              cycle_days = item.cycle_days.map((item) => {
                return item * 1;
              });
            this.circleDay.forEach((item) => {
              if (cycle_days.indexOf(item.key) != -1) {
                result.push(item.label);
              }
            });
            item.cycleDayName = result.join("，");
          }

          return item;
        });
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //显示添加模态框
    showModal (record) {
      if (record == "add") {
        //添加
        this.showCheckOffice = true;
        this.addMenu = true;
        this.checkAllOffice = true;
        this.checkAllPost = true;
        this.checkAllDay = false;
        this.checkAllMealTime = false;
        this.menuParam = {
          title: "", //必填
          price: "", //必填
          style: this.searchTypeId, //is_fixed=0时必选
          stock: 9999,
          is_fixed: 0,//this.fixedId == this.searchTypeId ? 1 : 0, //是否固定餐 1:是 0 ：否
          open_office: this.officesData.map((item) => {
            return (item = item.key);
          }),
          open_post: this.postsData.map((item) => {
            return (item = item.key);
          }),
          first_price_sub: 0,
          link_url: "",
          upper_count: 1,
          cycle_days: [],
          content: "",
          meal_id: [],
          cycle_status: 0,
          weight: 0,
          open_all:0
        };
        this.fileList = [];
      } else {
        //编辑
        this.showCheckOffice = true;
        this.addMenu = false;
        this.updateId = record.id;
        menuDetail(record.id).then((res) => {
          let detail = res.data;
          if (detail.open_office.length == this.officesData.length) {
            this.checkAllOffice = true;
          }
          if (detail.open_post.length == this.postsData.length) {
            this.checkAllPost = true;
          }
          if (detail.cycle_days.length == this.circleDay.length) {
            this.checkAllDay = true;
          }
          let arr = detail.meal_id.split(",").map((item) => {
            item = item * 1;
            return item;
          });
          if (arr.length == this.mealTimesData.length) {
            this.checkAllMealTime = true;
          }
          this.menuParam = {
            title: detail.title, //必填
            price: detail.price, //必填
            style: detail.style, //is_fixed=0时必选
            stock: detail.stock,
            is_fixed: detail.is_fixed, //是否固定餐 1:是 0 ：否
            open_office: detail.open_office.map((item) => item * 1),
            open_post: detail.open_post.map((item) => item * 1),
            first_price_sub: detail.first_price_sub,
            link_url: detail.link_url,
            upper_count: detail.upper_count,
            cycle_days: detail.cycle_days.map((item) => item * 1),
            content: detail.content,
            meal_id: arr,
            cycle_status: detail.cycle_status,
            weight: detail.weight,
            open_all:detail.open_all
          };

          this.fileList[0] = {
            uid: "1",
            name: "image.png",
            status: "done",
            url: detail.link_url,
          };
          // if (this.menuParam.is_fixed == 1) {
          //   this.disabled = true;
          // } else {
          //   this.disabled = false;
          // }

          if(detail.open_all == 1){
              this.showCheckOffice = false;
          }

        });
      }
      this.visible = true;
    },

    //选择菜品类型
    selectMenu (val) {
      
      this.menuParam.is_fixed = 0;
      //取消了固定餐, 全部都返回为非固定餐
      // if (val == this.fixedId) {
      //   this.menuParam.is_fixed = 1;
      // } else {
      //   this.menuParam.is_fixed = 0;
      // }
    },

    selectCycleStatus (val) {
      if (val == 1) {
        this.$confirm({
          title: "关闭提醒",
          content:
            "您即将关闭自动上架菜品，关闭后将不会再根据设置的菜品循环周期进行上架，关闭之前产生的每日菜谱上架菜品需要您前往<每日菜谱>进行移出菜谱操作",
          onOk: () => { },
          onCancel: () => {
            this.menuParam.cycle_status = 0;
          },
        });
      } else {
        this.$confirm({
          title: "开启提醒",
          content:
            "开启自动上架菜品，菜品将会根据您所设置的菜品循环周期进行自动上架至每日菜谱，如您当天不供应该菜品，可到<每日菜谱>进行移出菜谱操作",
          onOk: () => { },
          onCancel: () => {
            this.menuParam.cycle_status = 1;
          },
        });
      }
    },

    selectOpenAll (val) {
      if (val == 1) {
        this.showCheckOffice = false;
      } else {
        this.showCheckOffice = true;
      }
    },

    showTip () {
      this.$info({
        title: "自动上架",
        content:
          "开启自动上架菜品，菜品将会根据您所设置的菜品循环周期进行自动上架至每日菜谱，如您当天不供应该菜品，可到<每日菜谱>进行移出菜谱操作",
      });
    },

    //添加/编辑菜品
    handleUpdate: util.DebounceBy(function () {
      
      this.$refs["menuForm"].validate((valid) => {
        if (valid) {
          if (
            this.menuParam.cycle_status == 0 &&
            this.menuParam.cycle_days.length == 0
          ) {
            this.$message.info("请选择循环周期");
            return;
          }
          if (this.addMenu) {
            //添加
            menuCreate(this.menuParam)
              .then((res) => {
                if (res.data.msg.indexOf("成功") != -1) {
                  this.$message.success(res.data.msg);
                  this.visible = false;
                  this.queryParam.page = 1;
                  this.pagination.current = 1;
                  this.getTableData();
                }
              })
              .catch((err) => {
                if (err.status == 201) {
                  this.$message.success(err.data.msg);
                  this.visible = false;
                  this.queryParam.page = 1;
                  this.pagination.current = 1;
                  this.getTableData();
                } else {
                  let { errors } = err.data;
                  for (let i in errors) {
                    this.$message.error(errors[i][0]);
                  }
                }
              });
          } else {
            //编辑
            menuUpdate(this.updateId, this.menuParam)
              .then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              })
              .catch((err) => {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              });
          }
        }
      });
    }, 3000),

    //获取部门列表
    getOffices () {
      officesWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.officesData.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts () {
      postsWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.postsData.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取就餐时段列表
    getMealtimeList () {
      mealtimeListWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.mealTimesData.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //全选部门
    onCheckAllOffice (e) {
      this.menuParam.open_office = e.target.checked
        ? this.officesData.map((item) => {
          return (item = item.key);
        })
        : [];
      this.indeterOffice = false;
      this.checkAllOffice = e.target.checked;
    },

    //全选职务
    onCheckAllPost (e) {
      this.menuParam.open_post = e.target.checked
        ? this.postsData.map((item) => {
          return (item = item.key);
        })
        : [];
      this.indeterPost = false;
      this.checkAllPost = e.target.checked;
    },

    //全选周期
    onCheckAllDay (e) {
      this.menuParam.cycle_days = e.target.checked
        ? this.circleDay.map((item) => {
          return (item = item.key);
        })
        : [];
      this.indeterCircleDay = false;
      this.checkAllDay = e.target.checked;
    },

    //全选时段
    onCheckAllMealTime (e) {
      this.menuParam.meal_id = e.target.checked
        ? this.mealTimesData.map((item) => {
          return (item = item.key);
        })
        : [];
      this.indeterMealTime = false;
      this.checkAllMealTime = e.target.checked;
    },
    // 上传文件之前的钩子
    beforeUpload (file) {
      let self = this;
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function () {
            // 获取图片的宽高，并存放到file对象中
            file.width = this.width;
            file.height = this.height;
            if (file.size > (1024 * 300)) {
              self.$message.info('请上传300kb以下的图片')
              reject();
            }
            // if(file.width/file.height != 5/4){
            //   self.$message.info('图片宽高比需为5比4')
            //   reject();
            // }
            resolve();
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    //上传文件
    changeFile (info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
      info.file.status = "done";
    },

    //移除图片文件
    removePic (file) {
      this.menuParam.link_url = "";
      return true;
    },

    //上传组件自定义上传方式
    customRequest (data) {
      const formData = new FormData();
      formData.append("link_url", data.file);
      menuPicUpload(formData)
        .then((res) => { })
        .catch((err) => {
          if (err.status == 201) {
            this.menuParam.link_url = err.data.path;
          }
        });
    },

    //图片预览
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    //复制菜品
    copyFn (record) {
      let { id, is_fixed } = record;
      this.copy = {
        id,
        is_fixed
      }
      this.showDeleteOrSave = true
    },

    saveMealFn(){
      //保留
      this.showDeleteOrSave = false
      this.isSave = true
      this.showTypes = true
      this.confirmLoading = false
    },

    deleteMealFn(){
      //删除
      this.showDeleteOrSave = false
      this.isSave = false
      this.showTypes = true
      this.confirmLoading = false
    },

    askHandleTargetType(){
      if(this.isSave == false && this.copy.is_fixed != 0 && this.copyed.is_fixed != 1){
        this.showTypes = false
        this.$confirm({
          title:'提示',
          content:'复制成功后删除原菜品,是否执行删除菜品?',
          okText:'删除',
          onOk: async () => { 
            await this.handleTargetType()
          },
          onCancel:async () => {
            this.isSave = true
            await this.handleTargetType()
          },
        })
      }else{
        this.handleTargetType()
      }
    },

    async handleTargetType () {
      this.confirmLoading = true;
      this.menuTypeData.map(item => {
        if (item.id == this.TargetType) {
          this.copyed = {
            id: this.TargetType,
            is_fixed: item.is_fixed
          }
        }
      })
      let copyDetail = {};
      await menuDetail(this.copy.id).then((res) => {
        let detail = res.data;
        copyDetail = {
          title: `${detail.title}的副本`,
          price: detail.price,
          style: this.TargetType,
          stock: detail.stock,
          is_fixed: detail.is_fixed,
          open_office: detail.open_office.map((item) => item * 1),
          open_post: detail.open_post.map((item) => item * 1),
          first_price_sub: detail.first_price_sub,
          link_url: detail.link_url,
          upper_count: detail.upper_count,
          cycle_days: detail.cycle_days.map((item) => item * 1),//区别参数
          content: detail.content,
          weight: detail.weight,
          meal_id: detail.meal_id.split(','),//区别参数
          cycle_status: detail.cycle_status,//区别参数
          open_all: detail.open_all
        }
      });

      if (this.copy.is_fixed == 0 && this.copyed.is_fixed == 1) {
        //非固定复制到固定
        this.copyParam = {
          ...copyDetail,
          cycle_days: [],
          meal_id: [],
          cycle_status: 0,
          is_fixed: 1
        }
        this.showTypes = false;
        this.showReplenish = true
        this.confirmLoading2 = false
      }
      if (this.copy.is_fixed == this.copyed.is_fixed) {
        //同类型菜品复制
        this.copyParam = copyDetail
        menuCreate(this.copyParam)
          .then((res) => {
            if (res.data.msg.indexOf("成功") != -1) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading = false;
                    this.$message.success('复制成功');
                    this.showTypes = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading = false;
                this.$message.success('复制成功');
                this.showTypes = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            }
          })
          .catch((err) => {
            if (err.status == 201) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading = false;
                    this.$message.success('复制成功');
                    this.showTypes = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading = false;
                this.$message.success('复制成功');
                this.showTypes = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
              this.confirmLoading = false
            }
          });
      }
      if (this.copy.is_fixed == 1 && this.copyed.is_fixed == 0) {
        //固定复制到非固定
        this.copyParam = {
          ...copyDetail,
          is_fixed: 0
        }
        menuCreate(this.copyParam)
          .then((res) => {
            if (res.data.msg.indexOf("成功") != -1) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading = false;
                    this.$message.success('复制成功');
                    this.showTypes = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading = false;
                this.$message.success('复制成功');
                this.showTypes = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            }
          })
          .catch((err) => {
            if (err.status == 201) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading = false;
                    this.$message.success('复制成功');
                    this.showTypes = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading = false;
                this.$message.success('复制成功');
                this.showTypes = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
              this.confirmLoading = false
            }
          });
      }
    },

    saveReplenish () {
      if (this.copyParam.meal_id.length == 0) {
        this.$message.info('请选择就餐时段')
        return
      }
      if (this.copyParam.cycle_days.length == 0) {
        this.$message.info('请选择菜品循环周期')
        return
      }
      this.showReplenish = false
      this.$confirm({
        title:'提示',
        content:'复制成功后删除原菜品,是否执行删除菜品?',
        okText:'删除',
        onOk:  () => { 
          this.confirmLoading2 = true;
          menuCreate(this.copyParam)
          .then((res) => {
            if (res.data.msg.indexOf("成功") != -1) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading2 = false;
                    this.$message.success('复制成功');
                    this.showReplenish = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading2 = false;
                this.$message.success('复制成功');
                this.showReplenish = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            }
          })
          .catch((err) => {
            if (err.status == 201) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading2 = false;
                    this.$message.success('复制成功');
                    this.showReplenish = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading2 = false;
                this.$message.success('复制成功');
                this.showReplenish = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
        },
        onCancel: () => {
          this.isSave = true
          this.confirmLoading2 = true;
          menuCreate(this.copyParam)
          .then((res) => {
            if (res.data.msg.indexOf("成功") != -1) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading2 = false;
                    this.$message.success('复制成功');
                    this.showReplenish = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading2 = false;
                this.$message.success('复制成功');
                this.showReplenish = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            }
          })
          .catch((err) => {
            if (err.status == 201) {
              if (this.isSave == false) {
                menuDelete(this.copy.id).then().catch(err => {
                  if (err.status == 204) {
                    this.confirmLoading2 = false;
                    this.$message.success('复制成功');
                    this.showReplenish = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  }
                })
              } else {
                this.confirmLoading2 = false;
                this.$message.success('复制成功');
                this.showReplenish = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              }
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
        },
      })
      
      
    },

    //全选时段
    onCheckAllMealTime2 (e) {
      this.copyParam.meal_id = e.target.checked
        ? this.mealTimesData.map((item) => {
          return (item = item.key);
        })
        : [];
      this.indeterMealTime2 = false;
      this.checkAllMealTime2 = e.target.checked;
    },

    //全选周期
    onCheckAllDay2 (e) {
      this.copyParam.cycle_days = e.target.checked
        ? this.circleDay.map((item) => {
          return (item = item.key);
        })
        : [];
      this.indeterCircleDay2 = false;
      this.checkAllDay2 = e.target.checked;
    },

    selectCycleStatus2 (val) {
      if (val == 1) {
        this.$confirm({
          title: "关闭提醒",
          content:
            "您即将关闭自动上架菜品，关闭后将不会再根据设置的菜品循环周期进行上架，关闭之前产生的每日菜谱上架菜品需要您前往<每日菜谱>进行移出菜谱操作",
          onOk: () => { },
          onCancel: () => {
            this.copyParam.cycle_status = 0;
          },
        });
      } else {
        this.$confirm({
          title: "开启提醒",
          content:
            "开启自动上架菜品，菜品将会根据您所设置的菜品循环周期进行自动上架至每日菜谱，如您当天不供应该菜品，可到<每日菜谱>进行移出菜谱操作",
          onOk: () => { },
          onCancel: () => {
            this.copyParam.cycle_status = 1;
          },
        });
      }
    },

    //删除菜品
    deleteFn (record) {
      let id = record.id,
        title = record.title;
      this.$confirm({
        title: `确定删除菜品${title}？`,
        content: `删除菜品将自动移除每日菜谱但系统不对已报餐订单进行退餐，请自行退餐`,
        onOk: () => {
          menuDelete(id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 204) {
                this.$message.success("删除成功");
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              } else {
                this.$message.error("删除失败，请联系客服");
              }
            });
        },
      });
    },

    //获取菜品分类
    getMenuType () {
      menuTypes().then((res) => {
        let data = res.data;
        this.menuTypeData = data;
        /*留存用户还有标记为固定餐的数据,虽然已经无固定餐定义, 但是为了保留用户使用习惯,仍然将这些分类放前面*/
        this.menuTypeData.sort((a, b) => {
          if (a.is_fixed == 1) {
            return -1;
          }
        });

        if(this.menuTypeData.length > 0){
          //默认选中第一个分类
          this.fixedId = this.menuTypeData[0].id;
          this.menuParam.style = this.menuTypeData[0].id;
          this.queryParam.style = this.menuTypeData[0].id;
          this.searchTypeId = this.menuTypeData[0].id;
          this.TargetType = this.menuTypeData[0].id;
        }

        this.getTableData();
        
      });
    },

    //菜品列表查询
    searchByType (record) {
      this.queryParam = {
        page: 1,
        style: record.id,
        title: ''
      };
      this.searchTypeId = record.id;
      this.pagination.current = 1;
      this.getTableData();
    },

    //打开菜品类别模态框
    showTypeModal (record) {
      //添加
      if (record == "add") {
        this.addType = true;
        this.typeParam = {
          title: "",
        };
      } else {
        //编辑
        this.addType = false;
        this.typeParam = {
          title: record.title,
          id: record.id,
        };
      }
      this.typeModalVisible = true;
    },

    //添加/编辑菜品类型
    handleUpdateType: util.DebounceBy(function () {
      this.$refs["TypeForm"].validate((valid) => {
        if (valid) {
          if (this.addType) {
            //添加
            menuTypeCreate(this.typeParam)
              .then((res) => { })
              .catch((err) => {
                if (err.status == 201) {
                  this.$message.success("添加成功");
                  this.typeModalVisible = false;
                  this.getMenuType();
                } else {
                  let { errors } = err.data;
                  for (let i in errors) {
                    this.$message.error(errors[i][0]);
                  }
                }
              });
          } else {
            //编辑
            menuTypeUpdate(this.typeParam.id, { title: this.typeParam.title })
              .then((res) => {
                this.$message.success("编辑成功");
                this.typeModalVisible = false;
                this.getMenuType();
              })
              .catch((err) => {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              });
          }
        }
      });
    }, 3000),

    //删除菜品类型
    deleteType (record) {
      let id = record.id,
        title = record.title;
        this.$confirm({
          content: `确定删除${title}类型？`,
          onOk: () => {
            menuTypeDelete(id)
              .then((res) => { })
              .catch((err) => {
                if (err.status == 204) {
                  this.$message.success("删除成功");
                  this.getMenuType();
                } else {
                  this.$message.error(err.data.msg);
                }
            });
        },
      });
    },

    // customRow (record, index) {
    //   return {
    //     on: {
    //       //鼠标单击行
    //       click: event => {
    //         console.log(record.title)
    //         this.searchByType(record);
    //       }
    //     }
    //   }
    // }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created () {
    await this.getMenuType();
    // this.getTableData();
    this.getOffices();
    this.getPosts();
    this.getMealtimeList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.container {
  padding-top: 53px;
  .mainBox {
    background-color: #f0f2f5;
    .show_left {
      background-color: white;
      width: 250px;
      padding: 0 10px;
      position: absolute;
      height: calc(92vh - 53px);
      overflow: auto;
    }
    .show_right {
      background-color: white;
      padding: 0 10px;
      width: calc(100% - 260px);
      float: right;
    }
  }
}
.btn-diy{
  padding:0;
}
</style>
<style>
.th-style{
  padding-left:2px!important;
  padding-right:0!important;
  text-align: center!important;
  font-size: 10px!important;
}
</style>